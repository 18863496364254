export const CourseAccessTypesEnum = {
  FullAccess: 'full_access',
  DrippingContent: 'dripping_content',
  Partial: 'partial_access',
}

export const courseAccessTypeTitles = {
  [CourseAccessTypesEnum.FullAccess]:
    'workflow.steps.enrollments.access_type.full_access',
  [CourseAccessTypesEnum.DrippingContent]:
    'workflow.steps.enrollments.access_type.drip_content',
  [CourseAccessTypesEnum.Partial]:
    'workflow.steps.enrollments.access_type.partial_access',
}
