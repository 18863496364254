import styled from 'styled-components'
import Button from 'shared/components/Button'

export const DriveSelectButtonUi = styled(Button)`
  font-size: 1rem;
  border: 1px solid #eaecef;
  border-right: 1px solid var(--select-border-color);
  border-radius: 0px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  color: var(--content-text-color);
  background-color: #eaecef;
  margin: 0;
`
