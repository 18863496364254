export const POSITION_CHANGE_EVENTS = ['click', 'keydown', 'keyup']

export const listenToPositionChange = (element, callback) => {
  POSITION_CHANGE_EVENTS.forEach(event => {
    element.addEventListener(event, callback)
  })

  return () => {
    POSITION_CHANGE_EVENTS.forEach(event => {
      element.removeEventListener(event, callback)
    })
  }
}

export const getCaretPosition = () => {
  try {
    const selection = document.getSelection()
    const pos = selection.getRangeAt(0).startOffset
    const anchor = selection.anchorNode
    return { anchor, position: pos }
  } catch {
    return null
  }
}

export const setCaretPosition = ({ position, anchor }) => {
  const range = document.createRange()
  const sel = window.getSelection()

  range.setStart(anchor, position)
  range.collapse(true)

  sel.removeAllRanges()
  sel.addRange(range)
}

export const getFirstChildNode = element => {
  const child = element?.firstChild

  if (child?.nodeValue !== null) return child

  return getFirstChildNode(child)
}

export const pasteNewOption = (option, target, position) => {
  target = target ?? ''
  return [
    target.slice(0, position),
    `{{anchor (rich '${option}')}}`,
    target.slice(position),
  ].join('')
}
