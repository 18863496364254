import styled from 'styled-components'
import Group from 'shared/components/Group'

export const GDriveFileSelectUi = styled(Group)`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  border: 1px solid #70707074;
  border-radius: 6px;
`
