import React from 'react'

const LINE_HEIGHT = 20

const Line = ({ startX, startY, height = LINE_HEIGHT }) => {
  return (
    <line
      x1={startX}
      x2={startX}
      y1={startY}
      y2={startY + height}
      stroke="#c0c0c0"
      strokeDasharray="8 4"
    />
  )
}

export const height = LINE_HEIGHT

export default Line
