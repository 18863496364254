import {
  GoogleActionDisabledReasonLoco,
  IntegrationAvailabilityEnum,
} from 'workflow/consts/integration-availability'
import { useIntegrationAvailability } from './use-integration-availability'
import { useTranslation } from 'react-i18next'

export const useMappingAvailability = mappingId => {
  const { t } = useTranslation()
  const {
    data: integrationStatus,
    isLoading,
    mutate,
  } = useIntegrationAvailability()

  if (!mappingId) return {}

  const mappingIntegrationStatus = integrationStatus?.[mappingId]
  const reasonLocoKey = GoogleActionDisabledReasonLoco[mappingIntegrationStatus]

  return {
    status: mappingIntegrationStatus,
    available: mappingIntegrationStatus === IntegrationAvailabilityEnum.Ok,
    reason: reasonLocoKey && t(...reasonLocoKey),
    isLoading,
    mutate,
  }
}
