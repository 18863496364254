import { NetworkError } from 'errors'
import React, { useRef, useLayoutEffect, useState, useEffect } from 'react'
import Button from 'shared/components/Button'
import { testMapping } from 'workflow/api'
import useSelectOptionsData from 'workflow/hooks/useSelectOptionsData'
import { workflowDataTypes } from 'workflow/consts/workflowDataTypes'
import Suspense from 'shared/components/Suspense/Suspense'
import CheckCircleIcon from 'shared/icons/check-circle-icon'
import CrossCircleIcon from 'shared/icons/cross-circle-icon'
import {
  ActionContainerUi,
  TestingContainerUi,
  TestResultsUi,
  TestResultUi,
  TestResultColumnUi,
  TestResultSkeleton,
  ResultsStatusMessageUi,
} from './ui'
import { getMappingResult } from './utils'
import { useTranslation } from 'react-i18next'
import { MAPPING_FORM_MAX_HEIGHT } from 'workflow/consts/google-action'

export default function MappingTest(props) {
  const { t } = useTranslation()
  const testResultsRef = useRef()
  const abortRef = useRef()
  const [isLoading, setIsLoading] = useState(false)
  const [testResults, setTestResults] = useState()

  const {
    options: columns,
    isLoading: isLoadingColumns,
  } = useSelectOptionsData({
    dataType: workflowDataTypes.sheetColumns,
    // TODO: work on the naming. it's not clear at the moment
    parentEntityId: {
      driveId: props.driveId,
      spreadsheetId: props.spreadsheetId,
      sheetId: props.sheetId,
    },
    selector: column => ({ value: column.id, label: column.name }),
    fetchImmediately: true,
  })

  const handleTestMapping = async () => {
    setIsLoading(true)
    try {
      abortRef.current = new AbortController()
      const result = await testMapping({
        driveId: props.driveId,
        spreadsheetId: props.spreadsheetId,
        sheetId: props.sheetId,
        mapping: props.mapping,
        signal: abortRef.current.signal,
      })

      setTestResults(result)
    } catch (error) {
      if (error instanceof NetworkError) return
      window.rollbar.error('Failed to test google action mapping', error)
    }

    setIsLoading(false)
  }

  const hasError = !!testResults?.sentValues?.length

  useLayoutEffect(() => {
    if (testResultsRef.current) {
      const resizeObserver = new ResizeObserver(() => {
        if (testResultsRef.current?.clientHeight > MAPPING_FORM_MAX_HEIGHT) {
          testResultsRef.current.style.overflowY = 'auto'
          testResultsRef.current.style.maxHeight = `${MAPPING_FORM_MAX_HEIGHT}px`
        }
      })

      resizeObserver.observe(testResultsRef.current)

      return () => resizeObserver.disconnect()
    }
  }, [])

  useEffect(() => {
    return () => {
      abortRef.current?.signal && abortRef.current.abort()
    }
  }, [])

  return (
    <TestingContainerUi>
      <Suspense isLoading={isLoadingColumns} fallback={<TestResultSkeleton />}>
        <TestResultsUi ref={testResultsRef} className="customScroll">
          {!!testResults && (
            <ResultsStatusMessageUi error={hasError}>
              {hasError ? (
                <>
                  <CrossCircleIcon />{' '}
                  {t(
                    'workflow.steps.action.add_row_to_google_sheet.testing.failure',
                  )}
                </>
              ) : (
                <>
                  <CheckCircleIcon />{' '}
                  {t(
                    'workflow.steps.action.add_row_to_google_sheet.testing.success',
                  )}
                </>
              )}
            </ResultsStatusMessageUi>
          )}
          {columns?.map(column => {
            const { hasError, text } = getMappingResult(column, testResults)

            return (
              <TestResultUi key={column.value}>
                <TestResultColumnUi error={hasError}>
                  {column.label}
                </TestResultColumnUi>
                <p style={{ margin: 0 }}>{text}</p>
              </TestResultUi>
            )
          })}
        </TestResultsUi>
      </Suspense>

      <ActionContainerUi>
        <Button onClick={handleTestMapping} isLoading={isLoading} success>
          {t(testResults ? 'global.test_again' : 'global.test')}
        </Button>
      </ActionContainerUi>
    </TestingContainerUi>
  )
}
