import styled, { css } from 'styled-components'

const ButtonUi = styled.button`
  font-family: AvertaPE, Helvetica, Arial, sans-serif;
  font-size: 14px;
  margin-right: 2px;
  margin-left: 2px;
  margin-bottom: 10px;
  color: #6b7c93;
  font-weight: 400;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: ${({ shadow }) => (shadow ? '0 2px 3px 0 #d5e0e8' : 'none')};
  border: solid 1px #f1f4f8;
  text-align: center;
  padding: ${({ wide }) => (wide ? '6px 20px' : '6px 10px')};
  cursor: pointer;
  line-height: 1.42857143;
  outline: none;
  transition: background-color 0.25s ease;
  display: flex;
  gap: 1rem;
  align-items: center;

  &:disabled {
    opacity: 0.5;
  }
  ${({ styles = {} }) => styles}
  ${p =>
    p.success &&
    css`
      border: none;
      background: #149efc;
      color: #fff;
      &:hover {
        background: #4bb6ff;
      }
    `}
`

export default ButtonUi
