import React, { useContext, useEffect, useState } from 'react'
import Rect from '../../shared/Rect'
import Text from '../../shared/Text'
import useWidth from 'workflow/hooks/useWidth'
import { TopLayerContext } from 'workflow/components/TopLayer'

const PADDING_X = 6
const BADGE_HEIGHT = 20
const OFFSET = 2

export default function ActionBadge({ y, centerX, gap, content }) {
  const [recalculateWidth, setRecalculateWidth] = useState(1)
  const { renderAtTopLayer } = useContext(TopLayerContext)
  const [textRef, textWidth] = useWidth(recalculateWidth)

  useEffect(() => {
    // a hack to force width recalculation
    setRecalculateWidth(prev => prev + 1)
  }, [content])

  if (!content) return null

  const width = textWidth + PADDING_X * 2
  const textX = centerX - textWidth / 2 + OFFSET
  const textY = y + BADGE_HEIGHT / 2 + OFFSET

  return renderAtTopLayer(
    <>
      <Rect
        x={centerX - width / 2}
        y={y}
        width={width + gap}
        fill="#fff"
        stroke="none"
        hoverFill="#4bb6ff"
        height={BADGE_HEIGHT}
      />
      <Text
        x={textX}
        y={textY}
        ref={textRef}
        name={content}
        data-testid="action-badge"
        fill="#142d63"
        font-size="12px"
      />
    </>,
  )
}
