import React from 'react'
import { EditStatusEnum } from './consts'
import { EditStatusIndicatorUi } from './ui/edit-status-indicator-ui'

export default function EditStatusIndicator({ status }) {
  const isVisible = status !== EditStatusEnum.Stale

  if (!isVisible) {
    return null
  }

  return <EditStatusIndicatorUi />
}
