import React from 'react'
import Courses from 'workflow/components/Courses'
import Group from 'shared/components/Group'
import CourseAccessTypes from 'workflow/components/course-access-types'
import CourseModules from 'workflow/components/course-modules'
import { CourseAccessTypesEnum } from 'workflow/consts/course'

const ActionTypeEnrollInCourse = ({ change, data }) => {
  const handleChangeCourse = ({ value, label }) => {
    change({ ...data, id: value, name: label })
  }

  const handleChangeAccessType = ({ value: accessType }) => {
    change({ ...data, courseAccessType: accessType, courseModules: undefined })
  }

  const handleChangeModule = modules =>
    change(data => ({ ...data, courseModules: modules }))

  return (
    <>
      <Group>
        <Courses onChange={handleChangeCourse} selected={data} />
      </Group>
      <Group>
        <CourseAccessTypes
          onChange={handleChangeAccessType}
          selected={data?.courseAccessType}
        />
      </Group>
      {data?.courseAccessType === CourseAccessTypesEnum.Partial && (
        <Group>
          <CourseModules
            courseId={data?.id}
            onChange={handleChangeModule}
            selected={data?.courseModules}
          />
        </Group>
      )}
    </>
  )
}

export default ActionTypeEnrollInCourse
