import { actionTypes } from './actionTypes'
import { isWorkspaceAssistant } from 'workflow/utils/user'

export const restrictedActionReasons = {
  wsAssistant: 'workflow.steps.action.disabled_reason.ws_assistant',
}

export const actionTypesRestrictions = {
  // Used for temp changes mainly to restrict access to the new actions
  // for regular users in testing process
}

export const actionTypesDisabledRestrictions = {
  [actionTypes.addRowToGoogleSheet]: user => {
    // disable for ws assistants
    const disabled = isWorkspaceAssistant(user)
    return {
      disabled,
      reason: disabled ? restrictedActionReasons.wsAssistant : '',
    }
  },
}

export const filterActionTypeByAccess = roles => actionType => {
  if (!roles || roles.length === 0) return true

  const roleRestrictions = actionTypesRestrictions[actionType]

  if (!roleRestrictions) return true

  return roleRestrictions.some(restriction => roles.includes(restriction))
}

export const disableActionTypeByAccess = user => actionTypeOption => {
  const isRestricted = actionTypesDisabledRestrictions[actionTypeOption.value]

  if (typeof isRestricted !== 'function') return actionTypeOption

  const { disabled, reason } = !user || isRestricted(user)

  return {
    ...actionTypeOption,
    isDisabled: disabled,
    disabledReason: reason,
  }
}
