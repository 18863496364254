import React from 'react'

export default function CrossCircleIcon(props) {
  return (
    <svg
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.04 21.04C15.5849 21.04 20.08 16.5449 20.08 11C20.08 5.45502 15.5849 0.959961 10.04 0.959961C4.49506 0.959961 0 5.45502 0 11C0 16.5449 4.49506 21.04 10.04 21.04Z"
        fill="CurrentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7677 13.9225C14.7676 13.9224 14.7676 13.9224 14.7676 13.9224L11.8462 11.001L14.7676 8.07955L14.7677 8.07946C14.7981 8.04904 14.8215 8.01242 14.8364 7.9721C14.8764 7.86512 14.8536 7.73999 14.7676 7.65396L13.3875 6.27393C13.3015 6.18792 13.1764 6.16516 13.0694 6.20509C13.0291 6.22003 12.9925 6.24347 12.962 6.27383C12.962 6.27387 12.962 6.27389 12.962 6.27392L10.0406 9.19535L7.11918 6.27394L7.11909 6.27385C7.08867 6.24349 7.05206 6.22004 7.01175 6.20511C6.90473 6.16516 6.77964 6.18794 6.69361 6.27395L5.31357 7.65399C5.22754 7.74002 5.20478 7.86513 5.24471 7.97211C5.25965 8.01242 5.28311 8.04904 5.31347 8.07947C5.31351 8.07949 5.31353 8.07953 5.31356 8.07956L8.23497 11.001L5.31356 13.9224L5.31348 13.9225C5.28312 13.9529 5.25966 13.9895 5.2447 14.0298C5.20475 14.1368 5.22753 14.2619 5.31356 14.348L6.6936 15.728C6.77965 15.814 6.90474 15.8368 7.01174 15.7968C7.05205 15.7819 7.08866 15.7584 7.1191 15.7281C7.11912 15.728 7.11916 15.728 7.11919 15.728L10.0406 12.8066L12.962 15.728L12.9621 15.7281C12.9925 15.7584 13.0291 15.7819 13.0694 15.7969C13.1764 15.8368 13.3015 15.814 13.3875 15.728L14.7676 14.3479C14.8536 14.2619 14.8764 14.1368 14.8364 14.0298C14.8215 13.9895 14.798 13.9529 14.7677 13.9225H14.7677Z"
        fill="white"
      />
    </svg>
  )
}
