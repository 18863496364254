import React from 'react'
import { useTranslation } from 'react-i18next'
import Select, { components } from 'react-select'
import SelectOptionDescriptionUi from './ui/SelectOptionDescriptionUi'
import SelectOptionUi from './ui/SelectOptionUi'
import SelectOptionIconUi from 'shared/components/ReactSelectEnhaced/ui/SelectOptionIconUi'

export const BaseOption = ({
  children,
  getValueIcon,
  valueDescriptions,
  getDescription,
  ...rest
}) => {
  const { t } = useTranslation()
  return (
    <SelectOptionUi title={t(rest.data.disabledReason)}>
      {getValueIcon && (
        <SelectOptionIconUi>
          {getValueIcon(rest.data.value)()}
        </SelectOptionIconUi>
      )}
      <div>
        {children}
        <SelectOptionDescriptionUi active={rest.isSelected}>
          {valueDescriptions
            ? t(valueDescriptions[rest.data.value])
            : getDescription
            ? getDescription(rest.data.value)
            : ''}
        </SelectOptionDescriptionUi>
      </div>
    </SelectOptionUi>
  )
}

export const styles = {
  option: base => ({
    ...base,
    borderBottom: `1px solid hsl(0, 0%, 90%, 1)`,
    height: '100%',
  }),
  control: base => ({
    ...base,
    height: '50px',
  }),
}

const ReactSelectEnhanced = ({
  onChange,
  selected,
  options,
  getValueIcon,
  valueDescriptions,
  ...rest
}) => {
  const { t } = useTranslation()

  const SingleValue = props => (
    <components.SingleValue {...props}>
      <BaseOption
        getValueIcon={getValueIcon}
        valueDescriptions={valueDescriptions}
        {...props}
      />
    </components.SingleValue>
  )

  const Option = props => (
    <components.Option {...props}>
      <BaseOption
        getValueIcon={getValueIcon}
        valueDescriptions={valueDescriptions}
        {...props}
      />
    </components.Option>
  )

  return (
    <Select
      onChange={onChange}
      options={options}
      maxMenuHeight={300}
      value={options.find(option => option.value === selected)}
      formatOptionLabel={({ label }) => t(label)}
      components={{ SingleValue, Option }}
      styles={styles}
      {...rest}
    />
  )
}

export default ReactSelectEnhanced
