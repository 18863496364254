export const IntegrationAvailabilityEnum = {
  Ok: 'OK',
  Disconnected: 'INTEGRATION_DISCONNECTED',
  MappingDisabled: 'MAPPING_DISABLED',
}

export const GoogleActionDisabledReasonLoco = {
  [IntegrationAvailabilityEnum.Disconnected]: [
    'workflow.steps.action.errors.google_integration_missing',
    {
      link: `${process.env.REACT_APP_API_HOST}/dashboard/profile/google-integration`,
    },
  ],
  [IntegrationAvailabilityEnum.MappingDisabled]: [
    'workflow.steps.action.errors.google_mapping_disabled',
  ],
}
