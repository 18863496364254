import React, { useCallback } from 'react'
import { useTabs } from './context/use-tabs'
import { TabTitleListUi } from './ui/tab-title-list-ui'
import { TabTitleUi } from './ui/tab-title-ui'

export default function TabList(props) {
  const { tab: currentTab, setTab } = useTabs()

  const handleClick = useCallback(key => () => setTab(key), [setTab])

  return (
    <TabTitleListUi>
      {props.tabs?.map(({ title, key }) => (
        <li key={key}>
          <TabTitleUi onClick={handleClick(key)} isActive={key === currentTab}>
            {title}
          </TabTitleUi>
        </li>
      ))}
    </TabTitleListUi>
  )
}
