import { useEffect } from 'react'
import useControlledSWRImmutable from 'shared/hooks/useControlledSWRImmutable'
import getApiUrlByDataType from 'workflow/utils/getApiUrlByDataType'

export function defaultOptionSelector(rawOption) {
  return {
    value: rawOption.id,
    label: rawOption.name,
  }
}

function selectOptions({
  rawData,
  selector,
  defaultOptions,
  filter,
  flatten,
  selectDataArray,
}) {
  if (!rawData) {
    if (defaultOptions) {
      return defaultOptions.map(selector ?? defaultOptionSelector)
    }

    return []
  }

  let rawOptions

  if (Array.isArray(rawData)) {
    rawOptions = rawData
  } else {
    rawOptions = rawData.items ?? selectDataArray(rawData) ?? []
  }

  if (typeof filter === 'function') {
    rawOptions = rawOptions.filter(filter)
  }

  const transformedOptions = rawOptions?.map(selector ?? defaultOptionSelector)

  if (flatten) return transformedOptions.flat()

  return transformedOptions
}

export default function useSelectOptionsData({
  dataType,
  parentEntityId,
  selector,
  defaultOptions,
  fetchImmediately = false,
  revalidate = false,
  filter,
  flatten = false,
  selectDataArray,
}) {
  const url = getApiUrlByDataType(dataType, parentEntityId)

  const { data, isLoading, allowFetch } = useControlledSWRImmutable(url, {
    revalidate,
  })

  useEffect(() => {
    if (fetchImmediately) {
      allowFetch()
    }
  }, [fetchImmediately])

  const options = selectOptions({
    rawData: data,
    selector,
    defaultOptions,
    filter,
    flatten,
    selectDataArray,
  })

  return {
    options,
    isLoading,
    allowFetch,
  }
}
