import React, { useEffect } from 'react'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'
import useSelectOptionsData from 'workflow/hooks/useSelectOptionsData'
import { workflowDataTypes } from 'workflow/consts/workflowDataTypes'

export default function CrmPipelineStages({ pipelineId, selected, onChange }) {
  const { t } = useTranslation()

  const { options, isLoading, allowFetch } = useSelectOptionsData({
    dataType: workflowDataTypes.crmPipelines,
    filter: pipeline => pipeline.id === pipelineId,
    selector: pipeline =>
      pipeline?.stages?.map(stage => ({
        label: stage.name,
        value: stage.id,
      })) ?? [],
    flatten: true,
    revalidate: true,
    defaultOptions: selected &&
      'id' in selected &&
      'name' in selected && [selected],
  })

  useEffect(() => {
    pipelineId && allowFetch()
  }, [pipelineId])

  return (
    <Select
      id="workflow-crm-pipeline-stages-select"
      onChange={onChange}
      options={options}
      maxMenuHeight={200}
      value={options.find(option => option.value === selected?.id)}
      noOptionsMessage={() => t('workflow.selectors.no_options')}
      placeholder={t('workflow.crm_pipelines_stage.placeholder')}
      isLoading={isLoading}
    />
  )
}
