import React from 'react'
import { workflowDataTypes } from 'workflow/consts/workflowDataTypes'
import useSelectOptionsData from 'workflow/hooks/useSelectOptionsData'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'

export default function CrmPipelines({ onChange, selected }) {
  const { t } = useTranslation()

  const { options, isLoading, allowFetch } = useSelectOptionsData({
    dataType: workflowDataTypes.crmPipelines,
    defaultOptions: selected && [selected],
  })

  return (
    <Select
      id="workflow-crm-pipeline-select"
      onChange={onChange}
      options={options}
      maxMenuHeight={200}
      value={options.find(option => option.value === selected?.id)}
      noOptionsMessage={() => t('workflow.selectors.no_options')}
      placeholder={t('workflow.crm_pipelines.placeholder')}
      isLoading={isLoading}
      onFocus={allowFetch}
    />
  )
}
