import { workflowDataTypes } from 'workflow/consts/workflowDataTypes'
import useSelectOptionsData from 'workflow/hooks/useSelectOptionsData'
import React, { useEffect } from 'react'
import Select, { components } from 'react-select'
import { useTranslation } from 'react-i18next'
import CrossIcon from 'shared/icons/cross-icon'

const MultiValueRemove = props => (
  <components.MultiValueRemove {...props}>
    <CrossIcon style={{ color: 'rgba(20, 45, 99, 1)' }} />
  </components.MultiValueRemove>
)

export default function CourseModules({ courseId, selected, onChange }) {
  const { t } = useTranslation()
  const { options, isLoading, allowFetch } = useSelectOptionsData({
    dataType: workflowDataTypes.courseModules,
    parentEntityId: courseId,
    selectDataArray: data => data.modules,
    defaultOptions: !!selected?.length && selected,
  })

  const handleChange = modules =>
    onChange(modules?.map(({ value, label }) => ({ id: value, name: label })))

  const currentValues = options?.filter(({ value }) =>
    selected?.some(({ id }) => id === value),
  )

  useEffect(() => {
    if (courseId && !selected?.length) {
      allowFetch()
    }
  }, [courseId, selected])

  return (
    <Select
      id="workflow-course-modules-select"
      onChange={handleChange}
      isMulti
      closeMenuOnSelect={false}
      options={options}
      value={currentValues}
      maxMenuHeight={200}
      noOptionsMessage={() => t('workflow.courses.message.no_options')}
      placeholder={t('workflow.selectors.placeholder')}
      isLoading={isLoading}
      components={{ MultiValueRemove }}
      onFocus={allowFetch}
      styles={{
        multiValue: base => ({
          ...base,
          borderRadius: '50px',
          backgroundColor: 'rgba(241, 244, 248, 1)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '3px',
          padding: '0px 8px',
        }),
        multiValueLabel: base => ({
          ...base,
          color: 'rgba(20, 45, 99, 1)',
          fontSize: '16px',
          fontWeight: '400',
          textAlign: 'center',
        }),
        multiValueRemove: () => ({
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
        }),
      }}
    />
  )
}
