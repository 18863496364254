import React, {
  forwardRef,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from 'react'
import Group from 'shared/components/Group'
import { workflowDataTypes } from 'workflow/consts/workflowDataTypes'
import useSelectOptionsData from 'workflow/hooks/useSelectOptionsData'
import GoogleSheetRowMappings from './GoogleSheetRowMappings'
import FormErrors from 'shared/components/FormErrors'
import { useTranslation } from 'react-i18next'
import Tabs from 'shared/components/tabs'
import MappingTest from './mapping-test'
import { MAPPING_FORM_MAX_HEIGHT } from 'workflow/consts/google-action'

export const MAPPING_ACTIONS_CONTAINER_ID = 'mapping-actions-container'

const MappingTabs = {
  Mapping: 'data_mapping',
  Testing: 'data_testing',
}

const WorksheetColumns = forwardRef(function WorksheetColumns(
  { spreadsheetId, sheetId, driveId, mapping, onChange, disabled },
  ref,
) {
  const { t } = useTranslation()
  const resizeObserver = useRef()

  const { options, allowFetch, isLoading } = useSelectOptionsData({
    dataType: workflowDataTypes.sheetColumns,
    parentEntityId: { driveId, spreadsheetId, sheetId },
    selector: column => ({ value: column.id, label: column.name }),
  })

  const handleChange = ({ column, contents }) =>
    onChange(column.value, contents)

  const hasColumns = !(
    driveId &&
    sheetId &&
    spreadsheetId &&
    !isLoading &&
    (!options || options?.length === 0)
  )

  const tabs = useMemo(
    () => [
      {
        title: t(
          'workflow.steps.action.add_row_to_google_sheet.tabs.data_mapping',
        ),
        key: MappingTabs.Mapping,
      },
      {
        title: t(
          'workflow.steps.action.add_row_to_google_sheet.tabs.data_testing',
        ),
        key: MappingTabs.Testing,
      },
    ],
    [t],
  )

  const handleTabChange = useCallback(newTab => {
    if (newTab === MappingTabs.Mapping) return

    ref.current &&
      Object.values(ref.current).forEach(mapping => {
        if (!mapping) return

        const { column: columnId, contents } = mapping.getValue()
        onChange(columnId, contents)
      })
  }, [])

  const observeResizing = ref => {
    if (!ref) return

    if (resizeObserver.current) resizeObserver.current.disconnect()

    resizeObserver.current = new ResizeObserver(() => {
      if (ref?.clientHeight > MAPPING_FORM_MAX_HEIGHT) {
        ref.style.overflowY = 'auto'
        ref.style.maxHeight = `${MAPPING_FORM_MAX_HEIGHT}px`
      }
    })

    resizeObserver.current.observe(ref)
  }

  useEffect(() => {
    if (
      (!options || options.length === 0) &&
      driveId &&
      sheetId &&
      spreadsheetId
    ) {
      allowFetch()
    }
  }, [options, driveId, sheetId, spreadsheetId])

  useEffect(() => {
    return () => resizeObserver.current && resizeObserver.current.disconnect()
  }, [])

  return (
    <>
      {options && options.length > 0 && (
        <Tabs tabs={tabs} onTabChange={handleTabChange}>
          <Tabs.TabPanel tab={MappingTabs.Mapping}>
            <div ref={observeResizing} className="customScroll">
              {options.map(opt => (
                <Group key={opt.value}>
                  <GoogleSheetRowMappings
                    column={opt}
                    mapping={
                      mapping?.find(map => map.column === opt.value)?.value
                    }
                    onChange={handleChange}
                    disabled={disabled}
                    ref={el => (ref.current[opt.value] = el)}
                  />
                </Group>
              ))}
            </div>
          </Tabs.TabPanel>
          <Tabs.TabPanel tab={MappingTabs.Testing}>
            <MappingTest
              spreadsheetId={spreadsheetId}
              sheetId={sheetId}
              driveId={driveId}
              mapping={mapping}
            />
          </Tabs.TabPanel>
        </Tabs>
      )}
      {!hasColumns && (
        <FormErrors
          errors={[t('workflow.steps.action.form.validation.no_columns')]}
        />
      )}
    </>
  )
})
export default WorksheetColumns
