import TabsContext from './context'
import React, { useState, useCallback } from 'react'
import TabPanel from './tab-panel'
import TabList from './tab-list'
import { TabsUi } from './ui/tabs-ui'

const Tabs = props => {
  const [currentTab, setCurrentTab] = useState(props.tabs?.[0]?.key)

  const setTab = useCallback(
    tabKey => {
      const newTab = props.tabs?.find(tab => tab.key === tabKey)?.key
      if (newTab) {
        props.onTabChange && props.onTabChange(newTab)
        setCurrentTab(newTab)
      }
    },
    [props.tabs],
  )

  return (
    <TabsContext.Provider value={{ tab: currentTab, setTab }}>
      <TabsUi>
        <TabList tabs={props.tabs} />
        <div>{props.children}</div>
      </TabsUi>
    </TabsContext.Provider>
  )
}

Tabs.TabPanel = TabPanel

export default Tabs
