import { actionTypes } from '../../actionTypes'
import { actionTypesDisabledRestrictions } from '../../action-type-restrictions'

export const modifyMapping = (mappings, columnId, contents) => {
  if (!mappings || mappings.length === 0) {
    return [{ column: columnId, value: contents }]
  }

  const exists = mappings.some(mapping => mapping.column === columnId)

  if (!exists) return [...mappings, { column: columnId, value: contents }]

  return mappings.map(mapping =>
    mapping.column === columnId ? { ...mapping, value: contents } : mapping,
  )
}

export const isDisabledEditing =
  actionTypesDisabledRestrictions[actionTypes.addRowToGoogleSheet]
