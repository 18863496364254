import React from 'react'
import Group from 'shared/components/Group'
import CrmPipelineStages from 'workflow/components/crm-pipeline-stages'
import CrmPipelines from 'workflow/components/crm-pipelines'

export default function ActionTypeAssignPipelineStage({ change, data }) {
  const handleChangePipeline = ({ value, label }) =>
    change({ pipeline: { id: value, name: label } })

  const handleStageChange = ({ value, label }) =>
    change(data => ({ ...data, id: value, name: label }))

  return (
    <>
      <Group>
        <CrmPipelines
          onChange={handleChangePipeline}
          selected={data?.pipeline}
        />
      </Group>
      <Group>
        <CrmPipelineStages
          pipelineId={data?.pipeline?.id}
          onChange={handleStageChange}
          selected={data}
        />
      </Group>
    </>
  )
}
