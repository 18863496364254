import { useContext } from 'react'
import { TabsContext } from './context'

export const useTabs = () => {
  const tabs = useContext(TabsContext)

  if (tabs === undefined) {
    throw new Error('`useTabs` must be used within a TabsProvider')
  }

  return tabs
}
