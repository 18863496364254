import { workflowDataTypes } from 'workflow/consts/workflowDataTypes'
import useSelectOptionsData from 'workflow/hooks/useSelectOptionsData'
import React from 'react'
import useGDrivePicker from 'workflow/hooks/useGDrivePicker'
import { useTranslation } from 'react-i18next'
import { GDriveFileSelectUi, DriveSelectButtonUi, SelectedFileUi } from './ui'
import { GreenCheckCircleIcon } from 'shared/icons/green-check-circle-icon'

export default function GDriveFileSelect({
  driveId,
  onChange,
  value,
  disabled,
}) {
  const { t } = useTranslation()

  const { options: drives } = useSelectOptionsData({
    dataType: workflowDataTypes.drives,
    selector: drive => ({ id: drive.id, accessToken: drive.accessToken }),
    fetchImmediately: true,
  })

  const drive = drives?.find(d => d.id === driveId)

  const handleSelectFile = files => {
    const file = files?.[0]

    if (!file) return

    const fileAsOption = { value: file.id, label: file.name }
    onChange && onChange(fileAsOption)
  }

  const { initiated, showPicker, isLoading } = useGDrivePicker({
    accessToken: drive?.accessToken,
    onSelect: handleSelectFile,
  })

  return (
    <GDriveFileSelectUi>
      <DriveSelectButtonUi
        disabled={disabled || !initiated}
        isLoading={isLoading}
        onClick={showPicker}
      >
        {t('workflow.spreadsheets.placeholder')}
      </DriveSelectButtonUi>
      {value && (
        <SelectedFileUi>
          {value?.name}
          <GreenCheckCircleIcon />
        </SelectedFileUi>
      )}
    </GDriveFileSelectUi>
  )
}
