import styled, { css } from 'styled-components'

export const PseudoTextareaUi = styled.p`
  background-color: var(--field-bg-color);
  border: solid 1px var(--field-border-color);
  min-height: var(--field-height);
  height: fit-content;
  max-width: 100%;
  width: 100%;
  border-radius: 4px;
  align-items: center;
  display: inline-flex;
  justify-content: flex-start;
  line-height: 1.5;
  padding: calc(0.375em - 1px) calc(0.625em - 1px);
  position: relative;
  vertical-align: top;
  word-break: break-word;
  display: flex;
  flex-wrap: wrap;

  width: '100%';
  margin: 0;

  & span[data-type='rich'] {
    background-color: var(--field-bg-color);
    border: solid 1px var(--field-border-color);
    color: var(--field-color);
    padding: 1px 2px;
    border-radius: 4px;
    display: inline-flex;
    gap: 5px;
    align-items: center;

    &:hover {
      background: var(--field-border-color);
    }

    &[data-error='true'] {
      background-color: #ff00005e;
      border-color: #ff00005e;
      min-width: 20px;
      color: black;
    }

    & button[data-action='remove'] {
      all: unset;
      background-image: url('data:image/svg+xml,%3Csvg%20height%3D%2216%22%20viewBox%3D%220%200%20320%20512%22%20width%3D%2219%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%235c687e%22%20d%3D%22M310.6%20361.4c12.5%2012.5%2012.5%2032.75%200%2045.25C304.4%20412.9%20296.2%20416%20288%20416s-16.38-3.125-22.62-9.375L160%20301.3L54.63%20406.6C48.38%20412.9%2040.19%20416%2032%20416S15.63%20412.9%209.375%20406.6c-12.5-12.5-12.5-32.75%200-45.25l105.4-105.4L9.375%20150.6c-12.5-12.5-12.5-32.75%200-45.25s32.75-12.5%2045.25%200L160%20210.8l105.4-105.4c12.5-12.5%2032.75-12.5%2045.25%200s12.5%2032.75%200%2045.25l-105.4%20105.4L310.6%20361.4z%22%2F%3E%3C%2Fsvg%3E');
      background-position: center;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      border: solid 1px var(--field-border-color);
      padding: 2px;

      &:hover {
        border-color: red;
        background-image: url('data:image/svg+xml,%3Csvg%20height%3D%2216%22%20viewBox%3D%220%200%20320%20512%22%20width%3D%2219%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22red%22%20d%3D%22M310.6%20361.4c12.5%2012.5%2012.5%2032.75%200%2045.25C304.4%20412.9%20296.2%20416%20288%20416s-16.38-3.125-22.62-9.375L160%20301.3L54.63%20406.6C48.38%20412.9%2040.19%20416%2032%20416S15.63%20412.9%209.375%20406.6c-12.5-12.5-12.5-32.75%200-45.25l105.4-105.4L9.375%20150.6c-12.5-12.5-12.5-32.75%200-45.25s32.75-12.5%2045.25%200L160%20210.8l105.4-105.4c12.5-12.5%2032.75-12.5%2045.25%200s12.5%2032.75%200%2045.25l-105.4%20105.4L310.6%20361.4z%22%2F%3E%3C%2Fsvg%3E');
        cursor: pointer;
      }
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: var(--field-color);
      background-color: var(--field-disabled-bg-color);
      border-color: var(--field-disabled-border-color);
    `}
`
