import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import Dropdown from '../Dropdown'
import useClickOutside from 'shared/hooks/useClickOutside'
import PseudoTextarea from '../PseudoTextarea'
import Handlebars from 'handlebars'
import { LabelUi } from './ui/labelUi'
import {
  autoPlacement,
  autoUpdate,
  offset,
  useFloating,
} from '@floating-ui/react-dom'
import debounce from 'lodash.debounce'
import {
  listenToPositionChange,
  getCaretPosition,
  pasteNewOption,
  getFirstChildNode,
} from './utils'
import { EditStatusEnum } from './consts'
import { safeSetState } from 'shared/utils/safe-set-state'
import EditStatusIndicator from './edit-status-indicator'

const defaultContent =
  '<span data-spacer="true" contenteditable="true">&nbsp;</span>'

const editorHandlebar = Handlebars.create()

editorHandlebar.registerHelper('rich', function(property) {
  const replacement = this.replacements?.find(repl => repl.value === property)
  return new Handlebars.SafeString(
    `${defaultContent}<span data-type="rich" data-replacement-type="${
      replacement?.value
    }" contenteditable="false" ${
      !replacement ? 'data-error="true"' : ''
    }>${replacement?.label ?? '&nbsp;'}</span>${defaultContent}`,
  )
})

editorHandlebar.registerHelper('anchor', function(property) {
  const lookupElement = '<span'
  const propertyText = property.string
  const position = propertyText.lastIndexOf(lookupElement)
  const modifiedText = [
    propertyText.slice(0, position + lookupElement.length),
    ' data-anchor="true"',
    propertyText.slice(position + lookupElement.length),
  ].join('')
  return new Handlebars.SafeString(modifiedText)
})

const DropdownSelectInput = forwardRef(function DropdownSelectInput(
  {
    options,
    placeholder,
    onSave,
    contents,
    replacements,
    label,
    disabled,
    isLoading,
  },
  ref,
) {
  const [localContents, setLocalContents] = useState(contents || defaultContent)
  const dropdownRef = useRef()
  const inputRef = useRef()
  const [inFocus, setInFocus] = useState(false)
  const [position, setPosition] = useState(0)
  const [editStatus, setEditStatus] = useState(EditStatusEnum.Stale)

  const safelySetPosition = safeSetState(setPosition)

  const { refs, floatingStyles } = useFloating({
    placement: 'left',
    whileElementsMounted: autoUpdate,
    middleware: [autoPlacement(), offset(5)],
  })

  const isOpenDropdown = inFocus && !disabled

  const getInputValue = () => {
    const targetClone = inputRef.current?.cloneNode(true)
    if (targetClone) {
      targetClone
        .querySelectorAll('[data-error="true"]')
        .forEach(node => node.replaceWith(''))
      targetClone
        .querySelectorAll('span[data-type="rich"]')
        .forEach(node =>
          node.replaceWith(
            `{{rich '${node.getAttribute('data-replacement-type')}'}}`,
          ),
        )
    }

    return targetClone?.innerText.trim() || ''
  }

  const handleSave = useCallback(() => {
    if (!onSave || !isOpenDropdown) return

    onSave(getInputValue())
  }, [onSave, isOpenDropdown])

  useImperativeHandle(ref, () => ({
    getValue: getInputValue,
  }))

  const handleClickOutside = useCallback(() => {
    setInFocus(false)
    handleSave()
  }, [handleSave])

  useClickOutside([dropdownRef, inputRef], handleClickOutside)

  const handleSelect = option => {
    let anchor = position.anchor

    if (
      inputRef.current &&
      (!inputRef.current.contains(anchor) || inputRef.current === anchor) &&
      inputRef.current.innerText.trim().length === 0
    ) {
      inputRef.current.innerText = pasteNewOption(
        option.value,
        inputRef.current.innerText,
        position.position,
      )
    } else {
      anchor.nodeValue = pasteNewOption(
        option.value,
        anchor.nodeValue,
        position.position,
      )
    }

    inputRef.current
      .querySelectorAll('span[data-anchor="true"]')
      .forEach(el => el.removeAttribute('data-anchor'))

    setLocalContents(inputRef?.current.innerHTML ?? defaultContent)
  }

  const compileContents = () => {
    const contentsToCompile = localContents || defaultContent
    const template = editorHandlebar.compile(contentsToCompile)
    const result = template({ replacements })
    return result
  }

  const handlePositionChange = useCallback(event => {
    let currentPosition
    if (event.target?.getAttribute('data-type') === 'rich') {
      const positionAnchorElement = event.target.nextSibling
      const positionAnchorNode = getFirstChildNode(positionAnchorElement)
      currentPosition = { anchor: positionAnchorNode, position: 0 }
    } else {
      currentPosition = getCaretPosition()
    }

    safelySetPosition(currentPosition)
    setInFocus(true)
  }, [])

  const indicateSaved = debounce(() => {
    setEditStatus(EditStatusEnum.Saved)
  }, 1000)

  const handleContentChange = () => {
    setEditStatus(EditStatusEnum.Editing)
    indicateSaved()
  }

  useEffect(() => {
    if (!inputRef.current) return

    const clearPositionListener = listenToPositionChange(
      inputRef.current,
      handlePositionChange,
    )

    return () => {
      clearPositionListener()
    }
  }, [handlePositionChange])

  useEffect(() => {
    setLocalContents(contents || defaultContent)
  }, [contents])

  useEffect(() => {
    if (localContents && inputRef.current) {
      const anchorElement = inputRef.current.querySelector(
        'span[data-anchor="true"]',
      )

      if (!anchorElement) return

      const anchorNode = anchorElement.childNodes?.[0]

      if (!anchorNode) return

      safelySetPosition({ anchor: anchorNode, position: 0 })
    }
  }, [localContents])

  useEffect(() => {
    if (editStatus === EditStatusEnum.Saved) {
      setEditStatus(EditStatusEnum.Stale)
    }
  }, [editStatus, position])

  return (
    <div style={{ position: 'relative' }}>
      <LabelUi>{label}</LabelUi>
      <PseudoTextarea
        ref={el => {
          inputRef.current = el
          refs.setReference(el)
        }}
        onFocus={() => !disabled && setInFocus(true)}
        placeholder={placeholder}
        disabled={disabled}
        dangerouslySetInnerHTML={{ __html: compileContents() }}
        onInput={handleContentChange}
      />
      <EditStatusIndicator status={editStatus} />
      <Dropdown
        ref={ref => {
          dropdownRef.current = ref
          refs.setFloating(ref)
        }}
        isOpen={isOpenDropdown}
        options={options}
        onSelect={handleSelect}
        style={floatingStyles}
        isLoading={isLoading}
      />
    </div>
  )
})

export default DropdownSelectInput
