import styled from 'styled-components'

export const TabTitleUi = styled.button`
  all: unset;
  border-bottom: 3px solid
    ${({ isActive }) => (isActive ? `#4bb6ff` : `transparent`)};
  cursor: pointer;
  font-weight: 700;
  font-size: 1.2rem;
  color: ${({ isActive }) => (isActive ? `#142D63` : `#a1abc1`)};
  padding: 0.1rem 0.3rem 0.3rem;
  transition: all 0.4s ease-in-out;
`
