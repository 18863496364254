import React from 'react'

export default function CrossIcon(props) {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.5687 4.61475L3.79901 14.3845"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke="CurrentColor"
      />
      <path
        d="M13.5687 14.3845L3.79901 4.61475"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke="CurrentColor"
      />
    </svg>
  )
}
