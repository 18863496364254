import { ModalContext } from 'modal/context'
import React, { memo, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Circle from 'workflow/components/Step/shared/Circle'
import { stepChildTypes } from 'workflow/components/Step/stepChildTypes'
import StepCreate from 'workflow/components/Step/StepCreate'
import ActionEdit from 'workflow/components/Step/types/Action/ActionEdit'
import {
  actionTypesNames,
  getStepReplacements,
} from 'workflow/components/Step/types/Action/actionTypes'
import useHover from 'workflow/hooks/useHover'
import useWidth from 'workflow/hooks/useWidth'
import Line, { height as lineHeight } from '../../shared/Line'
import Remove, { radius as removeDimension } from '../../shared/Remove'
import Rect, { height as rectHeight } from '../../shared/Rect'
import Text, { padding as textPadding } from '../../shared/Text'
import Group from '../../shared/Group'
import { getActionIcon } from 'workflow/components/Step/types/Action/utils/get-action-icon'
import { svgSize } from 'workflow/components/Step/shared/Svg'
import GoogleActionWarning from './google-action-warning'
import GoogleActionRetryButton from './google-action-retry-button'
import ActionBadge from './action-badge'
import { getStepBadgeContent } from './utils/get-step-badge'

const totalHeight = 96
const HORIZONTAL_GAP = 20
const BADGE_GAP = 4

const Action = ({
  centerX,
  y,
  step,
  onRemove,
  addStep,
  level,
  heightSubscriber,
}) => {
  heightSubscriber(totalHeight)
  const [t] = useTranslation()
  const { showModal } = useContext(ModalContext)

  const name = t(actionTypesNames[step.type], getStepReplacements(step))

  const [ref, textWidth] = useWidth(name)
  const [hoverRef, isHovered] = useHover(false)
  const resultWidth = textWidth + textPadding * 2 + svgSize
  const maxWidth = (226 * 2) / (level - 1) - removeDimension
  const width = resultWidth > maxWidth ? maxWidth : resultWidth
  let calculatedName = name
  if (textWidth > width) {
    const ellipsisValue = resultWidth / width
    calculatedName =
      name.slice(0, Math.ceil(name.length / ellipsisValue) * 0.85) + '...'
  }

  const rectX = centerX - width / 2
  const rectY = y + lineHeight

  const iconX = centerX - width / 2 + textPadding
  const iconY = y + lineHeight / 2 + rectHeight / 2

  const gap = 5

  const textX = centerX - width / 2 + textPadding + svgSize + gap
  const textY = y + lineHeight + rectHeight / 2

  const removeX = centerX + width / 2 - removeDimension
  const removeY = y + lineHeight - removeDimension

  const startDownLineY = y + lineHeight + rectHeight

  const warningY = rectY + 10
  const warningX = rectX + width + HORIZONTAL_GAP
  const actionX = warningX + HORIZONTAL_GAP * 1.5

  const badgeContent = t(getStepBadgeContent(step))
  const shouldRenderBadge = !!badgeContent

  const extendedLineHeight = shouldRenderBadge ? lineHeight * 2 : lineHeight

  const circleY = y + rectHeight + lineHeight * (shouldRenderBadge ? 2.5 : 2)

  return (
    <g className="step-action" data-id={step.id} data-height={totalHeight}>
      <Line startX={centerX} startY={y} />
      <Group
        ref={hoverRef}
        onClick={() =>
          showModal(ActionEdit, {
            step,
          })
        }
        visibility={textWidth > 0 ? 'visible' : 'hidden'}
      >
        <Rect
          x={rectX}
          y={rectY}
          width={width + gap}
          fill="#149efc"
          stroke="none"
          hoverFill="#4bb6ff"
        />
        {getActionIcon(step.type)({
          x: iconX,
          y: iconY,
          width: svgSize,
          height: svgSize,
          fill: '#fff',
          stroke: '#fff',
        })}
        <Text
          x={textX}
          y={textY}
          ref={ref}
          name={calculatedName}
          data-testid="action-name"
          fill="#fff"
        />
        <title>{name}</title>
        <Remove
          x={removeX}
          y={removeY}
          visibility={isHovered}
          onClick={onRemove}
          data-testid="action-remove-button"
        />
      </Group>

      <GoogleActionWarning
        mappingId={step.mapping?.id}
        x={warningX}
        y={warningY}
      />

      <GoogleActionRetryButton
        mappingId={step.mapping?.id}
        x={actionX}
        y={warningY}
      />

      {shouldRenderBadge && (
        <ActionBadge
          centerX={centerX}
          gap={gap}
          y={startDownLineY + BADGE_GAP}
          content={badgeContent}
        />
      )}

      <Line
        startX={centerX}
        startY={startDownLineY}
        height={extendedLineHeight}
      />
      <Circle
        x={centerX}
        y={circleY}
        onClick={() =>
          showModal(StepCreate, {
            parentStepId: step.id,
            childType: stepChildTypes.child,
            addStep,
          })
        }
      />
    </g>
  )
}

export default memo(Action)
