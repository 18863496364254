import React from 'react'
import Select, { components } from 'react-select'
import { useTranslation } from 'react-i18next'
import {
  CourseAccessTypesEnum,
  courseAccessTypeTitles,
} from 'workflow/consts/course'

const options = Object.entries(CourseAccessTypesEnum).reduce(
  (acc, [_, key]) => {
    acc.push({
      value: key,
      label: courseAccessTypeTitles[key],
    })
    return acc
  },
  [],
)

const Option = props => {
  const { t } = useTranslation()
  return <components.Option {...props}>{t(props.label)}</components.Option>
}

export default function CourseAccessTypes({ selected, onChange }) {
  const { t } = useTranslation()

  const option = options.find(option => option.value === selected)

  return (
    <Select
      id="workflow-course-access-type-select"
      onChange={onChange}
      options={options}
      maxMenuHeight={200}
      value={option && { ...option, label: t(option.label) }}
      noOptionsMessage={() => t('workflow.courses.message.no_options')}
      placeholder={t('workflow.courses.access_type.placeholder')}
      components={{ Option }}
    />
  )
}
