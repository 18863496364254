import { useMappingAvailability } from 'workflow/hooks/use-mapping-availability'
import React, { useContext, useState } from 'react'
import { TopLayerContext } from 'workflow/components/TopLayer'
import Group from '../../shared/Group'
import useMapping from 'workflow/hooks/useMapping'
import RotateRightIcon from 'shared/icons/rotate-right'
import { LoadStateEnum } from 'shared/consts/load-state'
import { editMapping } from 'workflow/api'
import { GreenCheckCircleIcon } from 'shared/icons/green-check-circle-icon'

export default function GoogleActionRetryButton({ mappingId, x, y }) {
  const { available, isLoading, mutate } = useMappingAvailability(mappingId)
  const { renderAtTopLayer } = useContext(TopLayerContext)
  const { mapping } = useMapping({ mappingId })
  const [loadState, setLoadState] = useState(LoadStateEnum.Stale)

  const retryRequired = mappingId && !available && !isLoading

  if (!retryRequired && !(loadState === LoadStateEnum.Completed)) return null

  const handleRetryClick = async () => {
    setLoadState(LoadStateEnum.Loading)

    try {
      await editMapping(mapping)
      await mutate()
      setLoadState(LoadStateEnum.Completed)
      setTimeout(() => setLoadState(LoadStateEnum.Stale), 3000)
    } catch (error) {
      setLoadState(LoadStateEnum.Error)
    }
  }

  return renderAtTopLayer(
    <Group width="20px" height="20px" transform={`translate(${x}, ${y})`}>
      {loadState === LoadStateEnum.Completed ? (
        <GreenCheckCircleIcon
          style={{ stroke: 'none' }}
          width="19px"
          height="19px"
        />
      ) : (
        <RotateRightIcon
          onClick={handleRetryClick}
          style={{ color: '#149efc', '--center': '10px 10px' }}
          className={loadState === LoadStateEnum.Loading && 'animateRotate'}
          width="20px"
          height="20px"
        />
      )}
    </Group>,
  )
}
