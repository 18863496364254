import styled from 'styled-components'
import { animatePulse } from 'shared/styles/animatePulse'

export const ActionContainerUi = styled.div`
  display: flex;
  justify-content: end;
`

export const TestingContainerUi = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const TestResultsUi = styled.div`
  border: 1px solid #70707074;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 1rem;
  color: #142d63;
`

export const TestResultUi = styled.div`
  display: flex;
  gap: 0.75rem;
  align-items: center;
`

export const TestResultColumnUi = styled.p`
  border-radius: 4px;
  background-color: ${({ error }) =>
    error ? 'rgba(240, 68, 56, 0.2)' : 'rgba(0, 160, 255, 0.2)'};
  padding: 0.3rem 0.75rem;
  margin: 0;
`

export const TestResultSkeleton = styled.div`
  min-height: 10rem;
  width: 100%;
  background-color: rgb(155, 169, 179);
  border-radius: 0.25rem;

  ${animatePulse}
`

export const ResultsStatusMessageUi = styled.p`
  color: ${({ error }) =>
    error ? 'rgba(240, 68, 56, 1)' : 'rgba(17, 173, 132, 1)'};
  margin: 0;
  display: flex;
  gap: 0.5rem;
  align-items: center;
`
