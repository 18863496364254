import styled from 'styled-components/macro'

export const ModalCloseLinkUi = styled.a`
  position: absolute;
  display: block;
  cursor: pointer;
  height: 72px;
  width: 72px;
  padding: 24px;
  top: 0;
  right: 0;
`

export const ModalCloseIconUi = styled.i`
  font-size: 30px;
  font-style: normal;
  line-height: 16px;
  text-align: center;
  color: #000000;
  display: block;
  transition: color 150ms;
  ${ModalCloseLinkUi}:hover & {
    color: red;
  }
`
