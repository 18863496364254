import { NetworkError } from 'errors/NetworkError'
import { handleResponse } from 'shared/utils/handleResponse'
import httpClient from 'shared/utils/httpClient'
import {
  normalizeAddStep,
  normalizeAddTrigger,
  normalizeUpdateStep,
  normalizeUpdateTrigger,
} from 'workflow/normalizer'
import { getWorkflowId } from './utils/getWorkflowId'
import { actionTypes } from 'workflow/components/Step/types/Action/actionTypes.js'
import { normalizeMappings } from './utils/mappings'

export async function fetchWorkflow(id) {
  return fetch(
    `${process.env.REACT_APP_API_HOST}/dashboard/workflow-builder/api/workflow/${id}`,
    {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
      redirect: 'follow',
    },
  ).then(handleResponse)
}

export async function addStep(step, parentStepId = null, childType = null) {
  if (step.data.type === actionTypes.addRowToGoogleSheet) {
    const mapping = await createMapping(step.data.mapping)
    step.data.mapping.id = mapping?.id
  }

  const { id: workflowId } = getWorkflowId()
  return await httpClient.post(
    `/dashboard/workflow-builder/api/workflow/${workflowId}/step/new`,
    normalizeAddStep(step, parentStepId, childType),
  )
}

export async function addTrigger(
  workflowId,
  step,
  parentStepId = null,
  childType = null,
) {
  return httpClient.post(
    `/dashboard/workflow-builder/api/workflow/${workflowId}/step/new`,
    normalizeAddTrigger(step, parentStepId, childType),
  )
}

export async function removeStep(id) {
  return fetch(
    `${process.env.REACT_APP_API_HOST}/dashboard/workflow-builder/api/workflow/step/${id}/remove`,
    {
      credentials: 'include',
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
    .then(handleResponse)
    .catch(() => {
      throw new NetworkError()
    })
}

export async function editStep(step) {
  if (step.data.type === actionTypes.addRowToGoogleSheet) {
    const mapping = await editMapping(step.data.mapping)
    step.data.mapping.id = mapping?.id
  }

  const response = await httpClient.post(
    `/dashboard/workflow-builder/api/workflow/step/${step.id}/update`,
    normalizeUpdateStep(step),
  )
  return response
}

export function editTrigger(step) {
  return httpClient.post(
    `/dashboard/workflow-builder/api/workflow/step/${step.id}/update`,
    normalizeUpdateTrigger(step),
  )
}

export async function createMapping(mapping) {
  return await httpClient.post(
    `/api/dashboard/google-integration/spreadsheet-mappings?integrationId=${mapping.integrationId}`,
    {
      ...mapping,
      mapping: normalizeMappings(mapping.mapping),
      name: `${mapping.spreadsheetName} - ${mapping.sheetName}`,
    },
  )
}

export async function editMapping(mapping) {
  return await httpClient.put(
    `/api/dashboard/google-integration/spreadsheet-mappings/${mapping.id}?integrationId=${mapping.integrationId}`,
    {
      ...mapping,
      mapping: normalizeMappings(mapping.mapping),
      name: `${mapping.spreadsheetName} - ${mapping.sheetName}`,
    },
  )
}

export const testMapping = ({
  spreadsheetId,
  driveId,
  sheetId,
  mapping,
  signal,
}) =>
  httpClient.post(
    `/api/dashboard/google-integration/spreadsheets/${spreadsheetId}/sheets/${sheetId}/test_mapping?integrationId=${driveId}`,
    {
      workflowId: parseInt(getWorkflowId().id, 10),
      mapping: normalizeMappings(mapping),
    },
    {
      signal,
    },
  )
