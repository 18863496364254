import styled from 'styled-components/macro'

const ModalHeaderUi = styled.h1`
  color: #000;
  font-weight: bold;
  line-height: 24px;
  font-size: 20px;
  padding: 24px 90px 24px 30px;
  margin: 0;
`

export default ModalHeaderUi
