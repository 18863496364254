import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import DropdownSelectInput from 'shared/components/DropdownSelectInput'
import { workflowDataTypes } from 'workflow/consts/workflowDataTypes'
import useSelectOptionsData from 'workflow/hooks/useSelectOptionsData'
import { getWorkflowId } from 'workflow/utils/getWorkflowId'
import Handlebars from 'handlebars'
import { MappingContentsType } from 'workflow/consts/mappings'

function flattenContent(content, mappingOptions) {
  if (!content) return '&nbsp;'

  return content
    .map(cont => {
      if (cont.type === MappingContentsType.Raw) return cont.value
      const option = mappingOptions?.find(opt => opt.value === cont.value)
      return `{{rich '${option?.value}'}}`
    })
    .join('')
}

const SEPARATOR = '~$'

const rowMappingHandlebars = Handlebars.create()

rowMappingHandlebars.registerHelper(
  'rich',
  property => `${SEPARATOR}${property}${SEPARATOR}`,
)

function deflattenContent(content, mappingOptions) {
  if (!content || content.trim().length === 0) return []

  const template = rowMappingHandlebars.compile(content)
  const parsed = template({})
  const values = parsed.split(SEPARATOR)
  const contents = values.reduce((acc, next) => {
    const currentRow = {
      type: mappingOptions?.some(mo => mo.value === next)
        ? MappingContentsType.Var
        : MappingContentsType.Raw,
      value: next?.replace(/\s+/g, ' ') ?? '',
    }
    acc.push(currentRow)
    return acc
  }, [])
  return contents
}

const GoogleSheetRowMappings = forwardRef(function GoogleSheetRowMappings(
  { column, mapping, onChange, disabled },
  ref,
) {
  const inputRef = useRef()
  const { options: mappingSuggestions, isLoading } = useSelectOptionsData({
    dataType: workflowDataTypes.triggerMappingSamples,
    parentEntityId: getWorkflowId()?.id,
    selector: mapping => ({
      value: mapping.key,
      label: `${mapping.label}: ${mapping.value}`,
    }),
    fetchImmediately: true,
    revalidate: true,
  })

  const [localMapping, setLocalMapping] = useState(
    flattenContent(mapping ?? [], mappingSuggestions),
  )

  const handleSave = useCallback(
    mapping => {
      setLocalMapping(mapping)
      if (onChange) {
        const contents = deflattenContent(mapping, mappingSuggestions)

        onChange({
          column,
          contents: contents ?? [],
        })
      }
    },
    [mappingSuggestions?.length],
  )

  useImperativeHandle(ref, () => ({
    getValue: () => {
      const rawValue = inputRef.current?.getValue()
      const contents = deflattenContent(rawValue, mappingSuggestions)
      return { column: column.value, contents: contents ?? [] }
    },
  }))

  useEffect(() => {
    setLocalMapping(flattenContent(mapping, mappingSuggestions))
  }, [mappingSuggestions, mapping])

  return (
    <DropdownSelectInput
      ref={inputRef}
      label={column.label}
      options={mappingSuggestions}
      onSave={handleSave}
      contents={localMapping}
      replacements={mappingSuggestions}
      disabled={disabled}
      isLoading={isLoading}
    />
  )
})

export default GoogleSheetRowMappings
