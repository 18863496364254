export const getMappingResult = (column, results) => {
  if (!results) return { hasError: false, text: '' }

  const appendResultValue = results.appendedValues?.find(
    value => value.column === column.value,
  )

  if (appendResultValue?.value)
    return { hasError: false, text: appendResultValue.value }

  const sentResultValue = results.sentValues?.find(
    value => value.column === column.value,
  )

  return { hasError: true, text: sentResultValue?.value }
}
