export const safeSetState = setState => newState =>
  setState(prevState => {
    let res = newState

    if (typeof newState === 'function') {
      res = newState(prevState)
    }

    return res || prevState
  })
